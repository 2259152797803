<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Send Email</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleEmailSend" v-slot="{ errors }">
                        <contact-email-component v-model="form" :contacts="form.contact_id" :errors="errors" :success-handler="closeModal" ref="email-component" />
                        <div class="action_wpr mt-5">
                            <button :disabled="emailLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="emailLoader" class="btn save_btn" type="button" @click="handleEmailSend"><i class="fa fa-spinner fa-spin" v-if="emailLoader"></i>{{ emailLoader ? ' Sending' : 'Send' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Form } from 'vee-validate'
    import { mapState } from 'vuex'

    const ContactEmailComponent = defineAsyncComponent(() => import('@/pages/contact/components/EmailComponent'))

    export default {
        name: 'SendEmail',

        data () {
            return {
                form: {
                    contact_id: '',
                    source: 'contacts',
                    subject: '',
                    email: '',
                    is_email_conf: 0,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: Array,
            isConfirmation: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:modelValue'],

        watch: {
            contacts (contacts) {
                const vm = this;

                vm.form.contact_id = contacts.join(',');
            },

            modelValue (value) {
                const vm = this;

                vm.resetForm();

                if (value == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        components: {
            Form,
            ContactEmailComponent,
        },

        computed: mapState({
            user: state => state.authModule.user,
            emailLoader: state => state.contactModule.emailLoader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleEmailSend () {
                const vm = this;

                const emailComponent = vm.$refs['email-component'];

                emailComponent.handleEmailSend();
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contact_id: vm.contacts.join(','),
                    source: 'contacts',
                    subject: '',
                    email: '',
                    is_email_conf: 0,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: vm.user.logo,
                };

                if (vm.isConfirmation) {
                    vm.form.is_email_conf = 1;
                    vm.form.subject = 'Confirm Your Account';
                    vm.form.email   = `<p>Hi {{first_name}},</p>
                                       <p>{{company_owner}} from {{company_name}} is requesting permission to send you emails that may include newsletters, appointment reminders, and access to program materials. <br /><br /></p>
                                       <p>Please click the confirmation button below.<br /><br /></p>
                                       <p>{{{confirmation_url_btn}}}</p>
                                       <p><br /></p>
                                       <p>Thanks,</p>
                                       <p>{{company_name}}</p>`;
                }
            }
        },
    }
</script>
